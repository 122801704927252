// App.js
import React, { Suspense, lazy } from "react";
import ReactGA from "react-ga4";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ErrorBoundary from "./components/ErrorBoundary";
import "./index.css";

// Directly import section components
const Hero = lazy(() => import("./components/Hero"));
const About = lazy(() => import("./components/About"));
const Music = lazy(() => import("./components/Music"));
const Media = lazy(() => import("./components/Media"));
const Contact = lazy(() => import("./components/Contact"));

// Google Analytics Measurement ID
const MEASUREMENT_ID = "G-WLE7KCSMMQ";

function App() {
    React.useEffect(() => {
        ReactGA.initialize(MEASUREMENT_ID);
        ReactGA.send("pageview");
    }, []);

    return (
        <HelmetProvider>
            <div className="App">
                <Helmet>
                    <title>The Sun Kings - Wellington Rock Band</title>
                    <meta
                        name="description"
                        content="The Sun Kings are a rock band from Wellington, New Zealand, blending 90s rock and grunge influences into their unique sound."
                    />
                    <meta
                        name="keywords"
                        content="The Sun Kings, Wellington band, rock music, 90s rock, grunge, New Zealand music"
                    />
                    <link rel="canonical" href="https://thesunkings.nz" />

                    {/* Open Graph tags */}
                    <meta
                        property="og:title"
                        content="The Sun Kings - Wellington Indie Rock Band"
                    />
                    <meta
                        property="og:description"
                        content="Discover The Sun Kings, Wellington based indie rock band blending 90s rock and grunge influences."
                    />
                    <meta property="og:url" content="https://thesunkings.nz" />
                    <meta property="og:type" content="website" />

                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "MusicGroup",
                            name: "The Sun Kings",
                            description:
                                "The Sun Kings are a rock band from Wellington, New Zealand, blending 90s rock and grunge influences into their unique sound.",
                            genre: ["Rock", "Indie Rock", "Grunge"],
                            url: "https://thesunkings.nz",
                            sameAs: [
                                "https://www.facebook.com/TheSunKingsNZ",
                                "https://www.instagram.com/nzsunkings",
                                "https://open.spotify.com/artist/n2zI6DwczCQORZwgPLGxb",
                            ],
                            location: {
                                "@type": "Place",
                                name: "Wellington, New Zealand",
                            },
                        })}
                    </script>
                </Helmet>
                <ErrorBoundary>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Hero />
                        <About />
                        <Music />
                        <Media />
                        <Contact />
                    </Suspense>
                </ErrorBoundary>
            </div>
        </HelmetProvider>
    );
}

export default App;
